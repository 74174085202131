import {
  createStyles,
  Header,
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Text,
  Drawer,
  Divider,
  ActionIcon,
  Space,
  Anchor,
  useMantineTheme,
} from "@mantine/core";
import { useDisclosure, useMediaQuery, useWindowScroll } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import i18n, { Languages } from "../i18n";
import { NavLink } from "../interfaces/interfaces";
import { CoWSLogo } from "./Logo";

const scaleBgCol = (scrollY: number) => {
  const ostart = 1.0;
  const ostop = 0.55;
  const hstart = 400;
  const hstop = 0;

  return scrollY > hstart
    ? 1.0
    : ostart + (ostop - ostart) * ((scrollY - hstart) / (hstop - hstart));
};

const useStyles = createStyles((theme) => ({
  header: {
    borderBottom: 0,
    position: "fixed",
  },

  inner: {
    height: 60,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.md,
    textDecoration: "none",
    color: theme.white,
    fontSize: theme.fontSizes.md,
    fontWeight: 500,

    "&:hover": {
      borderColor: "#929493",
      borderWidth: "1px",
      borderStyle: "solid",
      backgroundColor: theme.colors.reds[0],
    },
  },

  linkDrawer: {
    display: "block",
    lineHeight: 1,
    padding: "10px 12px",
    borderRadius: theme.radius.xs,
    textDecoration: "none",
    color: theme.colors.primary,
    fontSize: theme.fontSizes.lg,
    fontWeight: 550,

    "&:hover": {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: "filled", color: theme.primaryColor })
          .background!,
        0.5
      ),
    },
  },

  linkLabel: {
    marginRight: 5,
  },
}));

interface HeaderSearchProps {
  links: NavLink[];
  fixed: boolean;
}

export function CoWSHeader({ links, fixed }: HeaderSearchProps) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [scroll, scrollTo] = useWindowScroll();
  const [opened, { toggle }] = useDisclosure(false);
  const { classes } = useStyles();

  const { t } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>{t(item.key)}</Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu key={link.key} trigger="hover" exitTransitionDuration={0}>
          <Menu.Target>
            <a
              href={link.link}
              className={opened ? classes.linkDrawer : classes.link}
            >
              <Center>
                <span className={classes.linkLabel}>{t(link.key)}</span>
              </Center>
            </a>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <a
        key={link.key}
        href={link.link}
        className={opened ? classes.linkDrawer : classes.link}
      >
        {t(link.key)}
      </a>
    );
  });

  const LanguageButtons = Object.values(Languages).map((lang) => {
    return (
      <ActionIcon
        key={lang.label}
        variant="subtle"
        onClick={() => changeLanguage(lang.value)}
        sx={{
          "&:hover": {
            borderColor: "#929493",
            borderWidth: "1px",
            borderStyle: "solid",
            backgroundColor: theme.colors.reds[0],
          },
        }}
      >
        <lang.flag
          w={22}
          radius="xs"
          opacity={i18n.language === lang.value ? 1.0 : 0.4}
        />
      </ActionIcon>
    );
  });

  return (
    <Header
      height={60}
      className={classes.header}
      mb={0}
      bg={
        fixed
          ? theme.colors.reds[1]
          : "rgba(74, 16, 7," + scaleBgCol(scroll.y) + ")"
      }
    >
      <Container fluid>
        <div className={classes.inner}>
          <Group>
            <CoWSLogo color={"white"} width={30} height={30} />
            <Anchor
              href="/"
              fw={900}
              fz={"lg"}
              color="white"
              underline={false}
              sx={{ fontFamily: "Megrim" }}
            >
              CoWS
            </Anchor>
            {mobile ? null : (
              <>
                <Divider
                  orientation="vertical"
                  size={"xs"}
                  variant={"dashed"}
                  color="#ffffff"
                />
                <Text
                  color={"white"}
                  fz={"lg"}
                  sx={{ fontFamily: "Advent Pro" }}
                >
                  {t("title")}
                </Text>
              </>
            )}
          </Group>
          {mobile ? (
            <Burger opened={opened} onClick={toggle} size="sm" color="#fff" />
          ) : (
            <Group spacing={5}>
              {items}
              {LanguageButtons}
            </Group>
          )}
          <Drawer
            opened={opened}
            withCloseButton={false}
            overlayOpacity={0.4}
            overlayBlur={3}
            onClose={toggle}
            position="right"
            shadow={"lg"}
            size="70%"
            styles={{
              drawer: {
                marginTop: "60px",
                backgroundColor: "rgba(255, 255, 255, 0.80)",
              },
            }}
          >
            <Space h="md" />

            {items}
            <Group sx={{ position: "absolute", bottom: 20, right: 20 }}>
              {LanguageButtons}
            </Group>
          </Drawer>
        </div>
      </Container>
    </Header>
  );
}
