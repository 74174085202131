import { List, useMantineTheme, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IFeature } from "../../interfaces/interfaces";
import {
  IconMap2,
  IconHome,
  IconSnowflake,
  IconTrees,
  IconDeviceLaptop,
  IconPresentation,
  IconToolsKitchen,
  IconWifi,
} from "@tabler/icons-react";
import React from "react";

const iconMap: Map<string, any> = new Map([
  ["location", IconMap2],
  ["place", IconHome],
  ["cool", IconSnowflake],
  ["surrounding", IconTrees],
  ["workplaces", IconDeviceLaptop],
  ["presentation", IconPresentation],
  ["kitchen", IconToolsKitchen],
  ["wifi", IconWifi],
]);

interface FeatureItemProps {
  feature: IFeature;
}

function FeatureItem({ feature }: FeatureItemProps) {
  const theme = useMantineTheme();

  return (
    <List.Item
      icon={React.createElement(iconMap.get(feature.key), {
        size: "2.5rem",
        color: theme.colors.reds[2],
        stroke: "0.17rem",
      })}
    >
      <Text ml={15} color="gray.7">
        {feature.title}
      </Text>
    </List.Item>
  );
}

export function Features() {
  const { t } = useTranslation();

  const features: IFeature[] = t("features", { returnObjects: true });

  return (
    <List spacing="lg" size="lg" center ml={20}>
      {features.map((f) => (
        <FeatureItem feature={f} key={f.key} />
      ))}
    </List>
  );
}
