import {
  ActionIcon,
  Divider,
  Footer,
  Group,
  Image,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconAt,
  IconBrandFacebookFilled,
  IconBrandInstagram,
  IconMapPin,
  IconPhone,
  IconSun,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import cowsBrand from "../assets/CoWS_Brand.png";
import { ContactIconsList } from "./contact/ContactInfoBox";

export function CowsFooter({ height }: { height: number }) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const { t } = useTranslation();

  const contactData = [
    {
      title: "Email",
      description: "info@cows-coworking.at",
      icon: IconAt,
      href: "mailto:info@cows-coworking.at",
    },
    {
      title: t("telephone"),
      description: "+43 660 65 30 323",
      icon: IconPhone,
      href: "tel:+436606530323",
    },
    {
      title: t("address"),
      description: "Staudach 3, 4060 Leonding",
      icon: IconMapPin,
    },
    {
      title: t("availability"),
      description: t("availabilityTime"),
      icon: IconSun,
    },
  ];

  return (
    <Footer
      children={
        <Group
          p={10}
          pl={mobile ? 5 : 40}
          pr={mobile ? 5 : 40}
          position="apart"
        >
          <Image src={cowsBrand} width={mobile ? 200 : 300} />
          <Group>
            <Text color={"#ffffff"}>© Martin Weigl 2023</Text>
            <Divider orientation="vertical" color={"#ffffff"} />
            <Text color={"#ffffff"} fw="bold" component="a" href="/contact">
              {t("imprint")}
            </Text>
            <Divider orientation="vertical" color={"#ffffff"} />
            <ActionIcon
              color="indigo"
              variant="filled"
              component="a"
              href="https://www.facebook.com/cowscoworking"
              target="_blank"
            >
              <IconBrandFacebookFilled size={16} />
            </ActionIcon>
            <ActionIcon
              color="pink.6"
              variant="filled"
              component="a"
              href="https://www.instagram.com/cows.coworking.space/"
              target="_blank"
            >
              <IconBrandInstagram size={24} stroke="2.0px" />
            </ActionIcon>
          </Group>
          <ContactIconsList data={contactData} variant="white" slim={true} />
        </Group>
      }
      height={mobile ? "100%" : 200}
      bg={theme.colors.reds[0]}
    />
  );
}
