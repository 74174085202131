import { AspectRatio, Group, Paper } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SubHeader } from "../main/Subheader";
import { ContactInfoBox } from "./ContactInfoBox";

export function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <SubHeader subHeaderHeight={350} text={t("contactTitle")} />
      <Paper
        mt={70}
        ml={20}
        mr={20}
        mb={80}
        shadow="lg"
        radius="lg"
        p="md"
        withBorder
      >
        <Group position="apart" spacing="lg">
          <ContactInfoBox />
          <AspectRatio ratio={16 / 9} w={800}>
            <iframe
              title="CoWS - Location"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11261.918677790301!2d14.232444758694822!3d48.248724305474056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47739694ff68201f%3A0x259f581ba57f0872!2sStaudach%203%2C%204060%20Staudach!5e0!3m2!1sen!2sat!4v1678643711020!5m2!1sen!2sat"
              style={{
                border: "0px",
              }}
            ></iframe>
          </AspectRatio>
        </Group>
      </Paper>
    </>
  );
}
