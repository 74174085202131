import { Carousel } from "@mantine/carousel";
import { Card, Image, Text, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import environment from "../../assets/images/01_work_environment.jpg";
import house from "../../assets/images/02_outside.jpg";
import insideMain from "../../assets/images/03_inside_main.jpg";
import kitchen from "../../assets/images/04_kitchen.jpg";
import couch from "../../assets/images/05_couch.jpg";
import toiletVault from "../../assets/images/06_toilet.jpg";

const data = [
  {
    image: environment,
    title: "carousel.environmentTitle",
  },
  {
    image: insideMain,
    title: "carousel.insideMainTitle",
  },
  {
    image: couch,
    title: "carousel.couchTitle",
  },
  {
    image: house,
    title: "carousel.outsideTitle",
  },
  {
    image: kitchen,
    title: "carousel.kitchenTitle",
  },
  {
    image: toiletVault,
    title: "carousel.architectureTitle",
  },
];

interface LPCardProps {
  image: string;
  title: string;
  mobile: boolean;
}

function LPCCard({ image, title, mobile }: LPCardProps) {
  const theme = useMantineTheme();
  const { t } = useTranslation();

  return (
    <Card
      shadow="md"
      p="md"
      radius="lg"
      withBorder
      h={"100%"}
      bg={theme.colors.reds[1]}
      sx={(theme) => ({
        borderColor: theme.colors.reds[1],
        borderWidth: "2px",
      })}
    >
      <Card.Section>
        <Image src={image} height={mobile ? 330 : 480} alt={"image " + title} />
      </Card.Section>

      <Text weight={500} size="lg" mt="lg" mb="lg" color={"#ffffff"}>
        {t(title)}
      </Text>
    </Card>
  );
}

export function LandingPageCarousel() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const autoplay = useRef(Autoplay({ delay: 3000 }));

  const slides = data.map((item) => (
    <Carousel.Slide key={item.title}>
      <LPCCard {...item} mobile={mobile} />
    </Carousel.Slide>
  ));

  return (
    <Carousel
      loop
      mx="auto"
      maw={600}
      slideGap={"md"}
      withIndicators
      plugins={[autoplay.current]}
      onMouseEnter={autoplay.current.stop}
      onMouseLeave={autoplay.current.reset}
    >
      {slides}
    </Carousel>
  );
}
