import * as React from "react";
import logo from "../assets/logoCoWS.png";

export function CoWSLogo({
  color,
  height,
  width,
}: {
  color: string;
  height: number;
  width: number;
}) {
  return (
    <a href="/">
      <img src={logo} width={width} height={height} alt="CoWS-Logo"></img>
      {/* <svg
        width={`${width}px`}
        height={`${height}px`}
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 73 70"
        style={{ fill: color }}
      >
        <path
          id="initial_logo"
          data-name="initial logo"
          className="cls-1"
          d="M10.2,10.5h1.6v23.7h10.3V10.5l1.5,1.6v22.1H34V7.7L26.3,0H0v34.2h10.2L10.2,10.5L10.2,10.5z
M59.2,12.1v11.6h-12l-1.6-1.6h12V12.1H43.1l-7.5,7.3l0,7.2l7.5,7.6h26.4V7.7L61.8,0H35.5v10.5h22.1L59.2,12.1z M26.2,58l7.8-7.7
l0-14.6H0v10.4l23.5,0l-1.5,1.6H7.4L0,55.2V70h34V59.6H10.2l1.6-1.6L26.2,58L26.2,58z M35.5,43.2v19.3l7.6,7.5h26.4V59.6H45.6
l0-11.8l1.6-1.6h12l-1.6,1.6H47.2V58h14.6l7.7-7.7V35.8H43.1L35.5,43.2z"
        />
      </svg> */}
    </a>
  );
}
