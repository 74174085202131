import { useTranslation } from "react-i18next";
import {
  Card,
  Image,
  Text,
  Badge,
  Button,
  Group,
  Center,
  Space,
  useMantineTheme,
  MantineTheme,
  Slider,
  Flex,
  Paper,
  Container,
  Stack,
} from "@mantine/core";
import singleDeskIcon from "../../assets/singleDesk.svg";
import meetingIcon from "../../assets/meeting.svg";
import saveMoneyIcon from "../../assets/saveMoney.svg";
import { PriceModel } from "../../interfaces/interfaces";
import { useState } from "react";
import { IconUsers, IconClock } from "@tabler/icons-react";
import React from "react";
import useWindowDimensions from "../../utils/hooks";
import { useMediaQuery } from "@mantine/hooks";

const priceDiscounts: number[][] = [
  [290, 285, 280, 275],
  [285, 280, 275, 270],
  [280, 275, 270, 265],
  [275, 270, 265, 260],
  [270, 265, 260, 255],
  [265, 260, 255, 250],
  [260, 255, 250, 245],
  [255, 250, 245, 240],
];

interface IMark {
  value: number;
  label: string;
  key: number;
}

function ValuePlanCard() {
  const theme = useMantineTheme();
  const [month, setMonth] = useState<number>(1);
  const [persons, setPersons] = useState<number>(3);
  const [price, setPrice] = useState<number>(priceDiscounts[persons][month]);
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  const { t } = useTranslation();

  const { height, width } = useWindowDimensions();

  const paperRef = React.useRef<HTMLInputElement>(null);
  const [paperHeight, setPaperHeight] = useState(0);

  React.useLayoutEffect(() => {
    if (paperRef.current?.clientHeight) {
      setPaperHeight(paperRef.current?.clientHeight);
    }
  }, []);

  const MONTHMARKS: IMark[] = [
    { value: 0, label: `1 ${t("pricing.month")}`, key: 0 },
    {
      value: Math.ceil(100 / 3),
      label: `3 ${t("pricing.month_plural")}`,
      key: 1,
    },
    {
      value: Math.ceil((100 / 3) * 2),
      label: `6 ${t("pricing.month_plural")}`,
      key: 2,
    },
    { value: 100, label: `12 ${t("pricing.month_plural")}`, key: 3 },
  ];

  const PERSONMARKS: IMark[] = [
    { value: 0, label: `1 ${t("pricing.person")}`, key: 0 },
    {
      value: Math.ceil(100 / 7),
      label: `2 ${t("pricing.person_plural")}`,
      key: 1,
    },
    {
      value: Math.ceil((100 / 7) * 2),
      label: `3 ${t("pricing.person_plural")}`,
      key: 2,
    },
    {
      value: Math.ceil((100 / 7) * 3),
      label: `4 ${t("pricing.person_plural")}`,
      key: 3,
    },
    {
      value: Math.ceil((100 / 7) * 4),
      label: `5 ${t("pricing.person_plural")}`,
      key: 4,
    },
    {
      value: Math.ceil((100 / 7) * 5),
      label: `6 ${t("pricing.person_plural")}`,
      key: 5,
    },
    {
      value: Math.ceil((100 / 7) * 6),
      label: `7 ${t("pricing.person_plural")}`,
      key: 6,
    },
    { value: 100, label: `8 ${t("pricing.person_plural")}`, key: 7 },
  ];

  React.useEffect(() => {
    setPrice(priceDiscounts[persons][month]);
  }, [month, persons]);

  if (mobile) {
    // Card for special price offers MOBILE VIEW
    return (
      <Card shadow="sm" p="lg" radius="lg" withBorder maw={400}>
        <Card.Section
          h={140}
          bg="dark"
          style={{
            background: theme.fn.linearGradient(
              35,
              theme.colors.primary[3],
              theme.colors.primary[9]
            ),
          }}
        >
          <Center>
            <Group spacing={"xl"} mt={20}>
              <Stack spacing={"xs"}>
                <Group>
                  <Text fz={30} fw={500} color="#fff" mt={-20}>
                    €
                  </Text>
                  <Text fz={50} fw={700} color="#fff">
                    {price},-
                  </Text>
                </Group>
                <Text fz={25} mt={-20} ml={10} color="#fff">
                  {t("pricing.periodMonth")}
                </Text>
              </Stack>
              <Space w={"lg"} />
              <Image src={saveMoneyIcon} width={100} />
            </Group>
          </Center>
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text weight={700} fz="lg">
            {t("pricing.valuePlan")}
          </Text>
          <Badge color="pink" variant="light" fz="sm">
            {MONTHMARKS.find((m) => m.key === month)!.label}
          </Badge>
        </Group>

        <Text size="sm" color="dimmed">
          {t("pricing.valuePlanDescription")}
        </Text>

        <Space h={50} />
        <Slider
          ml={10}
          mr={15}
          label={(val) =>
            MONTHMARKS.find((m) => m.value === Math.ceil(val))!.label
          }
          defaultValue={100 / 3}
          labelAlwaysOn
          step={100 / 3}
          marks={MONTHMARKS}
          thumbSize={30}
          thumbChildren={[<IconClock size={24} key="IconClock" />]}
          styles={{
            markLabel: { display: "none" },
            thumb: { borderWidth: 2, padding: 3 },
            mark: { padding: 4, marginTop: -2 },
          }}
          onChange={(v) =>
            setMonth(MONTHMARKS.find((m) => m.value === Math.ceil(v))!.key)
          }
        />
        <Space h={55} />
        <Slider
          ml={10}
          mr={15}
          label={(val) =>
            PERSONMARKS.find((p) => p.value === Math.ceil(val))!.label
          }
          defaultValue={(100 / 7) * 3}
          labelAlwaysOn
          step={100 / 7}
          marks={PERSONMARKS}
          styles={{
            markLabel: { display: "none" },
            thumb: { borderWidth: 2, padding: 3 },
            mark: { padding: 4, marginTop: -2 },
          }}
          thumbSize={30}
          thumbChildren={[<IconUsers size={24} key="IconUsers" />]}
          onChange={(v) =>
            setPersons(PERSONMARKS.find((p) => p.value === Math.ceil(v))!.key)
          }
        />
        <Space h="md" />

        <Button
          variant="outline"
          color="blue"
          fullWidth
          mt="md"
          radius="md"
          fz={"lg"}
          component="a"
          href="mailto:info@cows-coworking.at?subject=Anfrage CoWS"
        >
          {t("pricing.request")}
        </Button>
      </Card>
    );
  } else {
    // Card for special price offers WEB VIEW
    return (
      <Paper
        shadow="sm"
        radius="lg"
        p={0}
        withBorder
        sx={{ overflow: "hidden" }}
        ref={paperRef}
        w={width > 1250 ? 1250 : undefined}
      >
        {/* LEFT SIDE WITH PRICE */}
        <Group grow position="center" noWrap>
          <Center
            h={paperHeight}
            w={400}
            style={{
              background: theme.fn.linearGradient(
                35,
                theme.colors.primary[3],
                theme.colors.primary[9]
              ),
            }}
          >
            <Group spacing={"xl"} mt={20}>
              <Stack spacing={"xs"}>
                <Group>
                  <Text fz={30} fw={500} color="#fff" mt={-20}>
                    €
                  </Text>
                  <Text fz={50} fw={700} color="#fff">
                    {price},-
                  </Text>
                </Group>
                <Text fz={25} mt={-20} ml={10} color="#fff">
                  {t("pricing.periodMonth")}
                </Text>
              </Stack>
              <Image src={saveMoneyIcon} width={160} />
            </Group>
          </Center>

          {/* RIGHT SIDE OF VALUE CARD */}
          <Container mt={-20} mr={15} p="lg">
            <Group position="apart" mt="md" mb="xs">
              <Text weight={900} fz="xl">
                {t("pricing.valuePlan")}
              </Text>
              <Badge color="pink" variant="light" fz="sm">
                {MONTHMARKS.find((m) => m.key === month)!.label}
              </Badge>
            </Group>
            <Text size="sm" color="dimmed">
              {t("pricing.valuePlanDescription")}
            </Text>
            <Space h={50} />
            <Slider
              label={(val) =>
                MONTHMARKS.find((m) => m.value === Math.ceil(val))!.label
              }
              defaultValue={100 / 3}
              labelAlwaysOn
              step={100 / 3}
              marks={MONTHMARKS}
              thumbSize={30}
              thumbChildren={[<IconClock size={24} key="IconClock" />]}
              styles={{
                markLabel: { display: "none" },
                thumb: { borderWidth: 2, padding: 3 },
                mark: { padding: 4, marginTop: -2 },
              }}
              onChange={(v) =>
                setMonth(MONTHMARKS.find((m) => m.value === Math.ceil(v))!.key)
              }
            />
            <Space h={55} />
            <Slider
              label={(val) =>
                PERSONMARKS.find((p) => p.value === Math.ceil(val))!.label
              }
              defaultValue={(100 / 7) * 3}
              labelAlwaysOn
              step={100 / 7}
              marks={PERSONMARKS}
              styles={{
                markLabel: { display: "none" },
                thumb: { borderWidth: 2, padding: 3 },
                mark: { padding: 4, marginTop: -2 },
              }}
              thumbSize={30}
              thumbChildren={[<IconUsers size={24} key="IconUsers" />]}
              onChange={(v) =>
                setPersons(
                  PERSONMARKS.find((p) => p.value === Math.ceil(v))!.key
                )
              }
            />
            <Space h="md" />

            <Button
              variant="outline"
              color="blue"
              fullWidth
              mt="md"
              radius="md"
              fz={"lg"}
              component="a"
              href="mailto:info@cows-coworking.at?subject=Anfrage CoWS"
            >
              {t("pricing.request")}
            </Button>
          </Container>
        </Group>
      </Paper>
    );
  }
}

export function PricingModelCards() {
  const { t } = useTranslation();
  const theme = useMantineTheme();

  const models: PriceModel[] = t("pricing.models", { returnObjects: true });

  const modelCard = (priceModel: PriceModel, theme: MantineTheme) => (
    <Card
      shadow="sm"
      p="lg"
      radius="lg"
      withBorder
      maw={400}
      key={priceModel.model}
    >
      <Card.Section
        h={140}
        bg="dark"
        style={{
          background: theme.fn.linearGradient(
            35,
            theme.colors.primary[3],
            theme.colors.primary[9]
          ),
        }}
      >
        <Center>
          <Group spacing={"xl"} mt={20}>
            <Group>
              <Text fz={30} fw={500} color="#fff" mt={-20}>
                €
              </Text>
              <Text fz={50} fw={700} color="#fff">
                {priceModel.price},-
              </Text>
            </Group>
            <Space w={"lg"} />
            {priceModel.persons !== undefined && priceModel.persons > 1 ? (
              <Image src={meetingIcon} width={100} />
            ) : (
              <Image src={singleDeskIcon} width={100} />
            )}
          </Group>
        </Center>
      </Card.Section>

      <Group position="apart" mt="md" mb="xs">
        <Text weight={700} fz="lg">
          {priceModel.model}
        </Text>
        <Badge color="pink" variant="light" fz="sm">
          {priceModel.period}
        </Badge>
      </Group>

      <Text size="sm" color="dimmed">
        {priceModel.description}
      </Text>

      <Button
        variant="outline"
        color="blue"
        fullWidth
        mt="md"
        radius="md"
        fz={"lg"}
        component="a"
        href="mailto:info@cows-coworking.at?subject=Anfrage CoWS"
      >
        {t("pricing.request")}
      </Button>
    </Card>
  );

  return (
    <>
      <Flex
        gap={50}
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
        mb={20}
      >
        <Group position="center" spacing="xl">
          {models.map((m) => modelCard(m, theme))}
        </Group>
        <ValuePlanCard />
      </Flex>
    </>
  );
}
