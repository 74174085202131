import React from "react";
import { SubHeader } from "../main/Subheader";
import { View360 } from "./View360";

export function Discover({
  fixHeader,
}: {
  fixHeader: (value: boolean) => void;
}) {
  React.useEffect(() => {
    fixHeader(true);
  }, []);

  return (
    <>
      <SubHeader subHeaderHeight={60} noLogo={true} />
      <View360 />
    </>
  );
}
