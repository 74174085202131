import { MantineProvider } from "@mantine/core";
import { CoWSAPP } from "./CoWS";
import { cowsTheme } from "./styles/cowsTheme";

export default function App() {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={cowsTheme}>
      <CoWSAPP />
    </MantineProvider>
  );
}
