import {
  createStyles,
  ThemeIcon,
  Text,
  Box,
  Stack,
  Container,
  Anchor,
} from "@mantine/core";
import { IconSun, IconPhone, IconMapPin, IconAt } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type ContactIconVariant = "white" | "gradient";

interface ContactIconStyles {
  variant: ContactIconVariant;
}

const useStyles = createStyles((theme, { variant }: ContactIconStyles) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    color: theme.white,
  },

  icon: {
    marginRight: theme.spacing.md,
    backgroundImage:
      variant === "gradient"
        ? `linear-gradient(135deg, ${theme.colors[theme.primaryColor][4]} 0%, ${
            theme.colors[theme.primaryColor][6]
          } 100%)`
        : "none",
    backgroundColor: "transparent",
  },

  title: {
    color:
      variant === "gradient"
        ? theme.colors.gray[6]
        : theme.colors[theme.primaryColor][0],
  },

  description: {
    color: variant === "gradient" ? theme.black : theme.white,
  },
}));

interface ContactIconProps
  extends Omit<React.ComponentPropsWithoutRef<"div">, "title"> {
  icon: React.FC<any>;
  slim?: boolean;
  title: React.ReactNode;
  description: React.ReactNode;
  href?: string;
  variant?: ContactIconVariant;
}

function ContactIcon({
  icon: Icon,
  title,
  description,
  variant = "gradient",
  className,
  href,
  slim,
  ...others
}: ContactIconProps) {
  const { classes, cx } = useStyles({ variant });
  return (
    <div className={cx(classes.wrapper, className)} {...others}>
      {variant === "gradient" ? (
        <ThemeIcon size={40} radius="md" className={classes.icon}>
          <Icon size="1.5rem" />
        </ThemeIcon>
      ) : (
        <Box mr="md">
          <Icon size="1.5rem" />
        </Box>
      )}

      <div>
        {!slim ? (
          <Text size="xs" className={classes.title}>
            {title}
          </Text>
        ) : null}
        {href ? (
          <Anchor className={classes.description} href={href}>
            {description}
          </Anchor>
        ) : (
          <Text className={classes.description}>{description}</Text>
        )}
      </div>
    </div>
  );
}

interface ContactIconsListProps {
  data?: ContactIconProps[];
  variant?: ContactIconVariant;
  slim: boolean;
}

export function ContactIconsList({
  slim = false,
  data,
  variant,
}: ContactIconsListProps) {
  const items = data?.map((item, index) => (
    <ContactIcon key={index} variant={variant} {...item} slim={slim} />
  ));
  return <Stack spacing={slim ? "sm" : "xl"}>{items}</Stack>;
}

export function ContactInfoBox() {
  const { t } = useTranslation();

  const contactData = [
    {
      title: "Email",
      description: "info@cows-coworking.at",
      icon: IconAt,
      href: "mailto:info@cows-coworking.at",
    },
    {
      title: t("telephone"),
      description: "+43 660 65 30 323",
      icon: IconPhone,
      href: "tel:+436606530323",
    },
    {
      title: t("address"),
      description: "Staudach 3, 4060 Leonding",
      icon: IconMapPin,
    },
    {
      title: t("availability"),
      description: t("availabilityTime"),
      icon: IconSun,
    },
  ];

  return (
    <Container
      fluid
      sx={(theme) => ({
        padding: theme.spacing.xl,
        borderRadius: theme.radius.md,
        backgroundColor: theme.white,
      })}
    >
      <ContactIconsList data={contactData} slim={false} />
    </Container>
  );
}
