import {
  Badge,
  Button,
  Container,
  Grid,
  Group,
  Image,
  Paper,
  Space,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useTranslation } from "react-i18next";
import mainOffice from "../../assets/images/mainOffice.jpg";
import { useMediaQuery } from "@mantine/hooks";

export function CoWSDescription() {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  if (mobile) {
    return (
      <Paper shadow="md" p="md" radius="lg" withBorder m={20}>
        <Group position="left">
          <Image src={mainOffice} maw={600} radius={"xl"} alt="Office view" />
          <Container>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={700}>{t("descriptionCowsTitle")}</Text>
              <Badge color="pink" variant="light">
                {t("aboutBatch")}
              </Badge>
            </Group>
            <Text size="md" color="gray.6" sx={{ whiteSpace: "pre-line" }}>
              {t("descriptionCows")}
            </Text>
            <Group spacing={"md"}>
              <Button
                component="a"
                href="/contact"
                variant="outline"
                color="blue"
                mt="md"
                radius="md"
              >
                {t("visitCowsBtn")}
              </Button>
              <Button
                component="a"
                href="/tour"
                variant="outline"
                color="red.8"
                mt="md"
                radius="md"
              >
                {t("discover.tour")}
              </Button>
            </Group>
          </Container>
        </Group>
      </Paper>
    );
  }

  return (
    <Paper shadow="md" p="md" radius="lg" withBorder m={20}>
      <Grid>
        <Grid.Col span={"auto"}>
          <Image
            src={mainOffice}
            maw={800}
            radius={"xl"}
            p={15}
            alt="Office view"
          />
        </Grid.Col>
        <Grid.Col span={8}>
          <Container size={"xl"}>
            <Group position="apart" mt="md" mb="xs">
              <Text weight={700} size={"lg"}>
                {t("descriptionCowsTitle")}
              </Text>
              <Badge color="pink" variant="light" size="lg">
                {t("aboutBatch")}
              </Badge>
            </Group>
            <Text size="lg" color="gray.6" sx={{ whiteSpace: "pre-line" }}>
              {t("descriptionCows")}
            </Text>
            <Group spacing={"md"}>
              <Button
                component="a"
                href="/contact"
                variant="outline"
                color="blue"
                mt="md"
                radius="md"
              >
                {t("visitCowsBtn")}
              </Button>
              <Button
                component="a"
                href="/tour"
                variant="outline"
                color="red.8"
                mt="md"
                radius="md"
              >
                {t("discover.tour")}
              </Button>
            </Group>
          </Container>
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
