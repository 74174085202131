import {
  Center,
  Container,
  Group,
  Paper,
  Space,
  useMantineTheme,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { CoWSDescription } from "./CoWSDescription";
import { Features } from "./Features";
import { LandingPageCarousel } from "./LandingPageCarousel";
import { SubHeader } from "./Subheader";

export function Main() {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);

  return (
    <>
      <SubHeader subHeaderHeight={420} />
      <CoWSDescription />
      <Paper shadow="md" p="md" radius="lg" withBorder m={20} mb={80}>
        {mobile ? (
          <>
            <Container fluid>
              <LandingPageCarousel />
            </Container>
            <Center mt={30}>
              <Features />
            </Center>
          </>
        ) : (
          <Group position="left" grow>
            <Center>
              <Features />
            </Center>
            <Container fluid>
              <LandingPageCarousel />
            </Container>
          </Group>
        )}
      </Paper>
    </>
  );
}
