import { Center, Container } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { SubHeader } from "../main/Subheader";
import { PricingModelCards } from "./PricingModelCards";

export function Prices() {
  const { t } = useTranslation();

  return (
    <>
      <SubHeader subHeaderHeight={300} text={t("pricesTitle")} />
      <Container mt={70} mb={80} fluid>
        <Center>
          <PricingModelCards />
        </Center>
      </Container>
    </>
  );
}
