import {
  BackgroundImage,
  Center,
  Text,
  Transition,
  useMantineTheme,
  Image,
} from "@mantine/core";
import { useMediaQuery, useWindowScroll } from "@mantine/hooks";
import subHeaderImage from "../../assets/images/subheader.png";
import cowsBrand from "../../assets/CoWS_Brand.png";

interface SubHeaderProps {
  subHeaderHeight: number;
  text?: string;
  noLogo?: boolean;
}

export function SubHeader({ subHeaderHeight, text, noLogo }: SubHeaderProps) {
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const [scroll, scrollTo] = useWindowScroll();

  return (
    <BackgroundImage src={subHeaderImage}>
      <Center
        h={
          subHeaderHeight <= 60
            ? 60
            : mobile
            ? subHeaderHeight * 0.7
            : subHeaderHeight
        }
      >
        <Transition
          mounted={
            mobile
              ? scroll.y < subHeaderHeight / 7
              : scroll.y < subHeaderHeight / 3
          }
          transition="fade"
          duration={500}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={styles}>
              {text ? (
                <Center mt={30}>
                  <Text
                    color={"#fff"}
                    sx={{
                      fontFamily: "Advent Pro",
                    }}
                    fz={60}
                  >
                    {text}
                  </Text>
                </Center>
              ) : (
                <Center>
                  {!noLogo ? (
                    <Image src={cowsBrand} width={mobile ? 350 : 550} mt={50} />
                  ) : null}
                </Center>
              )}
            </div>
          )}
        </Transition>
      </Center>
    </BackgroundImage>
  );
}
