import { CoWSHeader } from "./components/Header";
import { NavLink } from "./interfaces/interfaces";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Main } from "./components/main/Main";
import { Contact } from "./components/contact/Contact";
import { Discover } from "./components/discover/Discover";
import { Prices } from "./components/prices/Prices";
import { CowsFooter } from "./components/Footer";
import { Flex } from "@mantine/core";
import { useState } from "react";

const pageLinks: NavLink[] = [
  { link: "/", key: "home" },
  { link: "tour", key: "tour" },
  { link: "prices", key: "prices" },
  { link: "contact", key: "contact" },
];

export function CoWSAPP() {
  const [headerFix, setHeaderFix] = useState(false);

  const fixHeader = (value: boolean) => {
    setHeaderFix(value);
  };

  return (
    <>
      <BrowserRouter>
        <Flex direction={"column"} justify={"space-between"} h={"100%"}>
          <div>
            <CoWSHeader links={pageLinks} fixed={headerFix} />
            <Routes>
              <Route index element={<Main />} key="index" />
              <Route
                path="tour"
                element={<Discover fixHeader={fixHeader} />}
                key="tour"
              />
              <Route path="prices" element={<Prices />} key="prices" />
              <Route path="contact" element={<Contact />} key="contact" />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </div>
          <CowsFooter height={200} />
        </Flex>
      </BrowserRouter>
    </>
  );
}
