import {
  ReactPhotoSphereViewer,
  MarkersPlugin,
} from "react-photo-sphere-viewer";
import React from "react";
import cowsPhotosphere from "../../assets/images/cowsPhotosphereMain.jpg";
import cowsPhotosphereToilet from "../../assets/images/cowsPhotosphereToilet.jpg";
import { Card, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../../utils/hooks";

export function View360() {
  const pSRef = React.useRef();
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm}px)`);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  React.useEffect(
    () => () => {
      // @ts-ignore
      return pSRef.current?.destroy();
    },
    []
  );

  const markers = {
    markers: [
      {
        id: "workplace1",
        position: { yaw: 3.94, pitch: -0.08 },
        image: require("../../assets/singleDeskWhite.png"),
        anchor: "bottom center",
        size: { width: 60, height: 60 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.rentWorkplace"),
      },
      {
        id: "workplace2",
        position: { yaw: 5.59, pitch: -0.07 },
        image: require("../../assets/singleDeskWhite.png"),
        anchor: "bottom center",
        size: { width: 65, height: 65 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.rentWorkplace"),
      },
      {
        id: "workplace3",
        position: { yaw: 0.48, pitch: -0.07 },
        image: require("../../assets/singleDeskWhite.png"),
        anchor: "bottom center",
        size: { width: 65, height: 65 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.rentWorkplace"),
      },
      {
        id: "kitchen",
        position: { yaw: 2.93, pitch: -0.02 },
        imageLayer: require("../../assets/coffeeWhite.png"),
        anchor: "bottom center",
        size: { width: 75, height: 75 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.kitchen"),
      },
      {
        id: "couch",
        position: { yaw: 3.458, pitch: -0.11 },
        imageLayer: require("../../assets/restingWhite.png"),
        anchor: "bottom center",
        size: { width: 85, height: 85 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.couch"),
      },
      {
        id: "meeting1",
        position: { yaw: 1.928, pitch: -0.46 },
        image: require("../../assets/meetingWhite.png"),
        anchor: "bottom center",
        size: { width: 85, height: 85 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.meeting"),
      },
      {
        id: "meeting2",
        position: { yaw: 4.14, pitch: -0.464 },
        image: require("../../assets/meetingWhite.png"),
        anchor: "bottom center",
        size: { width: 85, height: 85 },
        scale: { zoom: [0.8, 1] },
        tooltip: t("discover.meeting"),
      },
      {
        id: "toilet",
        position: { yaw: 2.2, pitch: 0.035 },
        image: require("../../assets/toiletWhite.png"),
        anchor: "bottom center",
        size: { width: 75, height: 75 },
        scale: { zoom: [0.8, 0.9] },
        tooltip: t("discover.toilet"),
      },
      {
        id: "wifi",
        position: { yaw: 1.99, pitch: 0.065 },
        imageLayer: require("../../assets/wifiWhite.png"),
        anchor: "bottom center",
        size: { width: 70, height: 70 },
        scale: { zoom: [0.8, 0.9] },
        tooltip: t("discover.wifi"),
      },
    ],
  };

  const handleReady = (instance: {
    getPlugin: (arg0: typeof MarkersPlugin) => any;
  }) => {
    const markersPlugs = instance.getPlugin(MarkersPlugin);
    if (!markersPlugs) return;

    markersPlugs.addEventListener("select-marker", (m: any) => {
      const id = m.marker.config.id;

      if (id === "backToOffice") {
        // @ts-ignore
        pSRef.current.setPanorama(cowsPhotosphere);
        markersPlugs.clearMarkers();
        markers.markers.forEach((m) => markersPlugs.addMarker(m));
      }

      if (id.includes("workplace") || id.includes("meeting")) {
        navigate("/prices");
      }

      if (id === "toilet") {
        // @ts-ignore
        pSRef.current.setPanorama(cowsPhotosphereToilet);
        markersPlugs.clearMarkers();
        markersPlugs.addMarker({
          id: "backToOffice",
          position: { yaw: 6.023, pitch: 0.0615 },
          image: require("../../assets/backWhite.png"),
          anchor: "bottom center",
          size: { width: 70, height: 70 },
          scale: { zoom: [0.8, 0.9] },
          tooltip: t("discover.toOffice"),
        });
      }
    });
    markersPlugs.showAllTooltips();
  };

  const plugins = [[MarkersPlugin, markers]];

  return (
    <Card
      shadow={"xl"}
      radius={"lg"}
      ml={mobile ? 20 : 60}
      mr={mobile ? 20 : 60}
      mt={mobile ? 20 : 40}
      mb={40}
      withBorder
      h={height * 0.75}
      p={0}
    >
      <ReactPhotoSphereViewer
        ref={pSRef}
        src={cowsPhotosphere}
        caption={t("descriptionCowsTitle")}
        navbar={["fullscreen", "zoom", "caption"]}
        keyboard="always"
        height={"100%"}
        width={"100%"}
        defaultZoomLvl={10}
        littlePlanet={false}
        onReady={handleReady}
        onClick={(c) => console.log(c.data.yaw, c.data.pitch)}
        container={""}
        // @ts-ignore
        plugins={plugins}
      ></ReactPhotoSphereViewer>
    </Card>
  );
}
