import { MantineThemeOverride } from "@mantine/core";

export const cowsTheme: MantineThemeOverride = {
  colors: {
    primary: [
      "#E9ECEF",
      "#DEE2E6",
      "#CED4DA",
      "#ADB5BD",
      "#6C757D",
      "#495057",
      "#495057",
      "#343A40",
      "#212529",
      "#1A1B1D",
    ],
    white: [
      "white",
      "white",
      "white",
      "white",
      "white",
      "white",
      "white",
      "white",
      "white",
      "white",
    ],
    blues: [
      "#E6F3FF",
      "#A8D7FF",
      "#79BCF5",
      "#54A5EB",
      "#3A8DD5",
      "#337AB7",
      "#206198",
      "#1C4F7B",
      "#053661",
      "#062C4E",
    ],
    reds: ["#290008", "#4a1007", "#520010", "#8F001D", "#B80025", "#E0002D"],
  },
  primaryShade: 5,
  primaryColor: "primary",
  fontFamily: "Tisa, sans",
  fontFamilyMonospace: "Monaco, Courier, monospace",
  headings: { fontFamily: "Greycliff CF, sans-serif" },
};
